import dynamic from 'next/dynamic';
import Head from 'next/head';

import Error from '@components/common/Error/Error';

export { getStaticProps } from '@data-fetch/404';

// Dynamically import Layout
const Layout = dynamic(() => import('@components/common/Layout/Layout'), {
  ssr: false, // Ensure it only renders on the client
});

const Custom404 = () => (
  <>
    <Head>
      <meta key="robots" name="robots" content="noindex,nofollow" />
    </Head>
    <Error statusCode={404} title="common:pageError.404" subtitle="common:pageError.notFoundErrorSubtitle" />
  </>
);

Custom404.Layout = Layout;
Custom404.isErrorPage = true;

export default Custom404;
